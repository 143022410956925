import TeamNameTag from "@comps/containers/TeamNameTag";
import useTournament from "@comps/hooks/useTournament";
import { ReduxState, Live } from "@defs/types";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

const mcs = makeStyles({
  body: {
    height: 1080,
    width: 1920,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  teams: {
    display: "flex",
    position: "absolute",
    top: 124,
  },
  wrapper: {
    transform: "scale(0.9032992036405006)",
  },
});
const PostGameMLBB = () => {
  const c = mcs();
  const { swap_team_positions: swap } = useSelector<ReduxState, Live>(
    (s) => s.live
  );
  const { team, match } = useTournament();
  const team1 = swap ? team(match?.player2_id) : team(match?.player1_id);
  const team2 = swap ? team(match?.player1_id) : team(match?.player2_id);
  return (
    <div className={c.body}>
      <div className={c.teams}>
        <div className={c.wrapper}>
          <TeamNameTag match={match} team={team1} />
        </div>
        <div style={{ width: 307 }}></div>

        <div className={c.wrapper}>
          <TeamNameTag match={match} team={team2} flipped />
        </div>
      </div>
    </div>
  );
};

export default PostGameMLBB;
