import { makeStyles, Theme } from "@material-ui/core";
import React from "react";
import TitleCard from "@img/NameTag.png";

const styles = makeStyles({
  body: {
    width: 497,
    height: 83,
    backgroundSize: "100% 100%",
    backgroundImage: `url("${TitleCard}")`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .text": {
      fontWeight: 900,
      color: (props: NameTagProps) =>
        props.color === "yellow" ? "#ffd231" : "#fff",
      fontSize: (props: NameTagProps) => props.fontSize ?? 35,
      lineHeight: 1,
      //   paddingBottom: 5,
    },
  },
});

interface NameTagProps {
  title: string;
  classes?: {
    root?: string;
    text?: string;
  };
  color?: "white" | "yellow";
  fontSize?: number;
  textProps?: any;
  [key: string]: any;
}

const NameTag = ({
  title,
  textProps,
  classes,
  color,
  fontSize = 35,
  ...props
}: NameTagProps) => {
  const c = styles({
    title,
    textProps,
    classes,
    color,
    fontSize,
    ...props,
  });
  return (
    <div className={c.body + " " + classes?.root} {...props}>
      <div className={"text " + classes?.text} {...textProps}>
        {title}
      </div>
    </div>
  );
};

export default NameTag;
