import { makeStyles } from "@material-ui/core";
import { Live, ReduxState } from "../../config/types/types";
import BracketContainer from "@view/BracketContainer";
import React, { CSSProperties } from "react";
import { useSelector } from "react-redux";
import label from "@img/label-bracket.png";

const easing = "cubic-bezier(0.65, 0, 0.35, 1)";
const duration = "1200ms";

const mcs = makeStyles({
  bracketModule: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    padding: "40px 0",
  },

  bracket: {
    height: 76,
    width: 440,
    backgroundSize: "100% 100%",
    backgroundImage: `url("${label}")`,
    position: "relative",
  },
  wrapper: {
    marginTop: 40,
    transitionProperty: "transform",
    transitionDuration: duration,
    transitionTimingFunction: easing,
  },
  label: {
    position: "absolute",
    top: 56,
    display: "flex",
    flexDirection: "column",
    zIndex: 999,
  },
  bracketName: {
    position: "absolute",
    top: "calc(100% + 4px)",
    color: "#fff",
    fontWeight: 500,
    fontSize: 18,
    textTransform: "uppercase",
    textAlign: "center",
    width: "100%",
    transitionProperty: "all",
    transitionDuration: "600ms",
    transitionTimingFunction: easing,
  },
});

const BracketModule: React.FC<{ className?: string }> = ({
  className,
  ...props
}) => {
  const c = mcs();
  const { isLowerBracket } = useSelector<ReduxState, Live>(
    (state) => state.live
  );

  const upperBracketStyles: CSSProperties = {
    transform: isLowerBracket ? "translateY(-10px)" : "translateY(0px)",
    opacity: isLowerBracket ? 0 : 1,
    transitionDelay: isLowerBracket ? "0ms" : "600ms",
  };
  const lowerBracketStyles: CSSProperties = {
    transform: isLowerBracket ? "translateY(0px)" : "translateY(-10px)",
    opacity: isLowerBracket ? 1 : 0,
    transitionDelay: isLowerBracket ? "600ms" : "0ms",
  };

  return (
    <div className={c.bracketModule + " " + className} {...props}>
      <div className={c.label}>
        <div className={c.bracket}>
          <div className={c.bracketName} style={upperBracketStyles}>
            Upper Bracket BO3
          </div>
          <div className={c.bracketName} style={lowerBracketStyles}>
            Lower Bracket BO3
          </div>
        </div>
      </div>

      <div
        className={c.wrapper}
        style={{
          transform: isLowerBracket ? "translateY(-50%)" : "translateY(0%)",
        }}
      >
        <BracketContainer />
      </div>
    </div>
  );
};

export default BracketModule;
