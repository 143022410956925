import CasterFrame from "@comps/containers/CasterFrame";
import TeamNameTag from "@comps/containers/TeamNameTag";
import useTournament from "@comps/hooks/useTournament";
import { Live, ReduxState } from "@defs/types";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

const mcs = makeStyles({
  body: {
    height: 1080,
    width: 1920,
    position: "relative",
  },
  teams: {
    display: "flex",
    position: "absolute",
    top: 19,
  },
  casters: {
    display: "flex",
    position: "absolute",
    top: 183,
  },
  casterCamWrapper: {
    transform: "scale(0.7963176064441887)",
  },
});

const Drafting = () => {
  const c = mcs();
  const { swap_team_positions: swap } = useSelector<ReduxState, Live>(
    (s) => s.live
  );
  const { team, match } = useTournament();
  const player1_id = match?.player1_id;
  const player2_id = match?.player2_id;
  const team1 = team(player1_id);
  const team2 = team(player2_id);
  return (
    <div className={c.body}>
      <div className={c.teams}>
        <TeamNameTag team={swap ? team2 : team1} match={match} />
        <div style={{ width: 578 }}></div>
        <TeamNameTag team={swap ? team1 : team2} match={match} flipped />
      </div>
      <div className={c.casters}>
        <div
          className={c.casterCamWrapper}
          style={{ transformOrigin: "right center" }}
        >
          <CasterFrame casterIndex={0} />
        </div>
        <div style={{ width: 140 }}></div>

        <div
          className={c.casterCamWrapper}
          style={{ transformOrigin: "left center" }}
        >
          <CasterFrame casterIndex={1} flipped />
        </div>
      </div>
    </div>
  );
};

export default Drafting;
