import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Live, Match, ReduxState } from "../../config/types/types";
import { format } from "date-fns";
import useTournament from "@hooks/useTournament";
import upNextLabel from "@img/label-schedule-upnext.png";
import scheduleLabel from "@img/label-schedule.png";
import schedTeam from "@img/sched-team.png";

const easing = "cubic-bezier(0.65, 0, 0.35, 1)";
const duration = "300ms";
const ms = makeStyles((theme) => ({
  module: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 41,
    marginRight: 20,
  },
  wrapper: {
    width: 1220,
    height: 605,
    display: "flex",
    "& > *": {
      flex: 1,
      flexShrink: 0,
      paddingTop: 31,
    },
  },
  label: {
    height: 76,
    width: 440,
    backgroundSize: "100% 100%",
  },
}));

const ScheduleModule: React.FC<{ className?: string }> = ({
  className,
  ...props
}) => {
  const c = ms();

  return (
    <div className={c.module}>
      <div className={c.wrapper + " " + className} {...props}>
        <Current />
        <Sched />
      </div>
    </div>
  );
};

const currentClasses = makeStyles({
  upNext: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "& .match-container": {
      marginTop: 47,
      display: "flex",

      "& .vs": {
        marginTop: 84,
        color: "#fbd239",
        fontWeight: "bolder",
        fontSize: 26,
      },

      "& .team": {
        width: 266,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        "& .logo": {
          height: 192,
          width: 192,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        },

        "& .org-name": {
          paddingTop: 10,
          fontSize: 24,
          fontWeight: "bolder",
          color: "#fff",
          textTransform: "uppercase",
          textAlign: "center",
        },
        "& .school-name": {
          fontSize: 18,
          fontWeight: "bold",
          color: "#fbd239",
          textTransform: "uppercase",
          textAlign: "center",
        },
      },
    },
  },
});

const Current: React.FC = () => {
  const c = currentClasses();
  const cc = ms();

  const { team, match } = useTournament();

  const team1 = team(match?.player1_id ?? 0);
  const team2 = team(match?.player2_id ?? 0);

  return (
    <div className={c.upNext}>
      <div
        className={cc.label}
        style={{ backgroundImage: `url("${upNextLabel}")` }}
      ></div>

      <div className="match-container">
        <div className="team">
          <div
            className="logo"
            style={{
              backgroundImage: `url("${team1?.logo_base64 || team1?.logo}")`,
            }}
          ></div>
          <div className="org-name">{team1?.org_name}</div>
          <div className="school-name">{team1?.university_name}</div>
        </div>
        <div className="vs">VS</div>
        <div className="team">
          <div
            className="logo"
            style={{
              backgroundImage: `url("${team2?.logo_base64 || team2?.logo}")`,
            }}
          ></div>

          <div className="org-name">{team2?.org_name}</div>
          <div className="school-name">{team2?.university_name}</div>
        </div>
      </div>
    </div>
  );
};

const schedClasses = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  schedule: {
    marginTop: 41,
    diplay: "flex",
    flexDirection: "column",
  },
  schedItem: {
    height: 39,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 25,

    "& .team": {
      height: 39,
      width: 210,
      backgroundSize: "100% 100%",
      backgroundImage: `url("${schedTeam}")`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      fontWeight: 900,
      lineHieght: 1,
      fontSize: 20,
    },
  },
  sched: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: 130,
    marginRight: 30,
    "& .time": {
      color: "#fff",
      fontSize: 30,
      fontWeight: "bolder",
      paddingRight: 3,
    },

    "& .timezone": {
      display: "flex",
      flexDirection: "column",
      color: "#fbd239",
      lineHeight: 1,
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  },
  vs: {
    color: "#fbd239",
    fontWeight: "bold",
    fontStyle: "italic",
    fontSize: 18,
    lineHieght: 1,
    margin: "0px 18px",
  },

  wrapper: {
    position: "relative",
  },

  filler: {
    width: "100%",
    marginTop: 41,
    diplay: "flex",
    flexDirection: "column",
    position: "absolute",
    display: "flex",
    top: 0,

    "& .item": {
      transition: `all ${duration} ${easing}`,
      height: 39,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      marginBottom: 25,
      paddingRight: 20,

      "& .line": {
        height: 10,
        width: "90%",
        background:
          "linear-gradient(90deg, rgba(250,173,59,1) 0%, rgba(234,60,90,1) 100%)",
        transform: "skew(-30deg)",
      },
    },
  },
});

const Sched: React.FC = () => {
  const cc = ms();
  const c = schedClasses();

  const { matches_today = [], match } = useSelector<ReduxState, Live>(
    (state) => state.live
  );

  const { team } = useTournament();

  const time = (date: Date) => {
    const newDate = new Date(date);
    const hours = newDate.getHours() % 12;
    const mins = newDate.getMinutes();
    const minutes = mins < 10 ? "0" + mins : mins;

    return `${hours}:${minutes}`;
  };
  const ampm = (date: Date) => {
    const newDate = new Date(date);
    const hours = newDate.getHours();

    return hours >= 12 ? "pm" : "am";
  };

  const matches = matches_today.filter((m) => m.id !== match?.id).slice(0, 6);

  return (
    <div className={c.container}>
      <div
        className={cc.label}
        style={{ backgroundImage: `url("${scheduleLabel}")` }}
      ></div>

      <div className={c.wrapper}>
        <div className={c.schedule}>
          {matches.map((m, i) => (
            <div className={c.schedItem} key={i}>
              <div className={c.sched}>
                <div className="time">{time(m.schedule ?? new Date())}</div>
                <div className="timezone">
                  <div>{ampm(m.schedule ?? new Date())}</div>
                  <div>+SGT</div>
                </div>
              </div>
              <div className="team">
                {team(m?.player1_id)?.university_acronym ||
                  team(m?.player1_id)?.name ||
                  "TBD"}
              </div>
              <div className={c.vs}>vs</div>
              <div className="team">
                {team(m?.player2_id)?.university_acronym ||
                  team(m?.player2_id)?.name ||
                  "TBD"}
              </div>
            </div>
          ))}
        </div>

        <div className={c.filler}>
          {[...Array(6)].map((item, i) => (
            <div
              className="item"
              style={{ opacity: i < matches.length ? 0 : 1 }}
            >
              <div className="line"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScheduleModule;
