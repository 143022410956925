const tourna = [
  {
    logo: null,
    universityName: "University dawd awd awd wad wa  dwa daw da ",
  },
  {
    logo: null,
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: "https://firebasestorage.googleapis.com/v0/b/remote-overlay-tool.appspot.com/o/Casters%2FUSC.png?alt=media&token=67a36d9f-cc40-478f-9d89-fb9c2c153b31",
    universityName: "University of San Carlos",
  },
  {
    logo: null,
    universityName: "University of San Carlos",
  },
  {
    logo: null,
    universityName: "University of San Carlos",
  },
];

export { tourna };
