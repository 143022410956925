import { makeStyles } from "@material-ui/core";

export default makeStyles({
  page: {
    height: 1080,
    width: 1080,
    position: "relative",
  },

  body: {
    position: "absolute",
    bottom: 47,
    left: -70,
  },
});
