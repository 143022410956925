import Casters from "@comps/slider/Casters.comp";
import React, { VFC } from "react";
import SliderStyle from "./Slider.style";

import { SwitchTransition, Transition } from "react-transition-group";
import { Transition as Trans } from "react-spring/renderprops";
import { useSelector } from "react-redux";
import { Live, LowerThirdsMode, ReduxState } from "@config/types/types";
import { FC } from "react";
import Ad from "@comps/slider/Ad.comp";
import useTournament from "@comps/hooks/useTournament";
import IngameLowerThirds from "@comps/ingameSliders/sponsors";

const duration = 600;

const defaultStyle = {
  transition: `all ${duration}ms cubic-bezier(0.65, 0, 0.35, 1)`,
  transform: "translateX(0px)",
  opacity: 1,
};

const transitionStyles = {
  unmounted: { opacity: 0, transform: "translateX(-40px)" },
  entering: { opacity: 0, transform: "translateX(-40px)" },
  entered: { opacity: 1, transform: "translateX(0px)" },
  exiting: { opacity: 0, transform: "translateX(-40px)" },
  exited: { opacity: 1, transform: "translateX(0px)" },
};

type SliderProps = {
  [key in LowerThirdsMode]?: JSX.Element;
};

const modes: SliderProps = {
  ad: <Ad />,
  casters: <Casters />,
};

const Slider: VFC = () => {
  const classes = SliderStyle();
  const { lowerThirdsIngame } = useSelector<ReduxState, Live>(
    (state) => state.live
  );

  return (
    <div className={classes.page}>
      <Trans
        items={lowerThirdsIngame?.is_live ?? false}
        from={{ opacity: 0, transform: "translateX(-40px)" }}
        enter={{ opacity: 1, transform: "translateX(0px)" }}
        leave={{ opacity: 0, transform: "translateX(-40px)" }}
      >
        {(item) =>
          item &&
          ((props) => (
            <div
              style={{
                ...props,
                height: 1080,
                width: 1920,
                position: "relative",
              }}
            >
              <SwitchTransition mode="out-in">
                <Transition
                  timeout={duration}
                  key={lowerThirdsIngame?.mode ?? "ticker"}
                  addEndListener={(node, done) =>
                    node.addEventListener("transitionend", done, false)
                  }
                >
                  {(state) => (
                    <div
                      className={classes.body}
                      style={{ ...defaultStyle, ...transitionStyles[state] }}
                    >
                      {modes[lowerThirdsIngame?.mode ?? "casters"]}
                    </div>
                  )}
                </Transition>
              </SwitchTransition>
            </div>
          ))
        }
      </Trans>
    </div>
  );
};

export default Slider;
