import { makeStyles } from "@material-ui/core";
import bg from "@img/sponsor.png";
import { VisibilityRounded } from "@material-ui/icons";

const pad = 70
export default makeStyles({
  body: {
    width: 1395,
    height: 164,
    paddingLeft: pad,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url("${bg}")`,
    position: "relative",

    '& .head': {
      height: 42,
      width: 474,
      textAlign: 'right',
      paddingRight: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      color: "#fbd239",
      fontFamily: "Roboto",
      fontWeight: 900,
      lineHeight: 1,
      fontSize: 28,
      whiteSpace:"pre",
      fontStyle: "italic",
      textTransform: "uppercase",
      transform:'translateY(1px)'
    },

    '& .body': {
      width:778,
      height: "calc(100% - 42px)",
      position: 'absolute',
      display: 'flex',
      // justifyContent: 'center',
      alignItems: 'center',
      left: 512 + pad,
      padding: 20,
      fontSize: 22,
      whiteSpace: "pre-wrap",
      // lineHeight: 1,
      color: "#fff",
    },

    "& .s-img-wrap": {
      height: "calc(100% - 43px)",
      position: "absolute",
      top: 43,
      width: 496,
      overflow: "visible",
      clipPath: `polygon(
        -100% -100%,
        200% -100%,
        200% calc(100% - 2px),
        -100% calc(100% - 2px))`,

      "& .s-img": {
        height: "100%",
        width: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      },
    },
  },
});
