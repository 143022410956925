import { Live, ReduxState } from "@config/types/types";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CastersStyles from "./Casters.styles";

const Casters: FC<{ alt?: boolean }> = () => {
  const { alt } = useParams<{ alt: string }>();
  const classes = CastersStyles();
  const { casters, casters_alt } = useSelector<ReduxState, Live>(
    (state) => state.live
  );

  const cast = alt ? casters_alt : casters;
  return cast?.length == 2  ? (
    <div className={classes.comp}>
      <div className={`${classes.caster} ${classes.caster1}`}>
        <div
          className={classes.casterInner}
          style={{
            backgroundImage: `url("${
              (cast && (cast[0]?.photo_base64 || cast[0]?.photo_url)) ?? ""
            }")`,
          }}
        ></div>
      </div>
      <div className={`${classes.caster} ${classes.caster2}`}>
        <div
          className={classes.casterInner}
          style={{
            backgroundImage: `url("${
              (cast && (cast[1]?.photo_base64 || cast[1]?.photo_url)) ?? ""
            }")`,
          }}
        ></div>
      </div>
      <div className={classes.details}>
        <div className="mic"></div>
        <div className="casters">
          <div className="caster">{cast[0].name}</div>
          <div className="caster">{cast[1].name}</div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default Casters;
