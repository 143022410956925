import CasterFrame from "@comps/containers/CasterFrame";
import TeamNameTag from "@comps/containers/TeamNameTag";
import useTournament from "@comps/hooks/useTournament";
import { Live, ReduxState } from "@defs/types";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

const mcs = makeStyles({
  body: {
    width: 1920,
    height: 1080,
    position: "relative",
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center",
  },
  lowerThirds: {
    display: "flex",
    position: "absolute",
    bottom: 130,
  },
  casters: {
    display: "flex",

    position: "absolute",
    bottom: 283,
  },
});

const CasterCam = () => {
  const c = mcs();
  const { swap_team_positions: swap } = useSelector<ReduxState, Live>(
    (s) => s.live
  );
  const { team, match } = useTournament();
  const player1_id = match?.player1_id;
  const player2_id = match?.player2_id;
  const team1 = team(player1_id);
  const team2 = team(player2_id);
  return (
    <div className={c.body}>
      <div className={c.lowerThirds}>
        <TeamNameTag team={team1} match={match} />
        <div style={{ width: 187 }}></div>
        <TeamNameTag team={team2} match={match} flipped />
      </div>

      <div className={c.casters}>
        <CasterFrame casterIndex={0} />
        <div style={{ width: 68 }}></div>
        <CasterFrame casterIndex={1} flipped />
      </div>
    </div>
  );
};

export default CasterCam;
