import { makeStyles } from "@material-ui/core";
import ParticipatingTeam2 from "../assets/imgs/participatingTeam2.png";
import QuestionImage from "../assets/imgs/questionMark.png";
import { useSelector } from "react-redux";
import { ReduxState } from "../config/types/types";
import { Transition } from "react-spring/renderprops-universal";
import { tourna } from "../mock";

const Styles = makeStyles({
  screen: {
    position: "relative",
    backgroundImage: `url(${ParticipatingTeam2})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 1080,
    width: 1920,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "& .wrapper": {
      marginTop: 150,
      width: 1500,
      height: 700,
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      // display: "grid",
      // gridAutoFlow: "column",
      // gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
      // gridTemplateRows: (props: { rows: number }) =>
      //   `repeat(${props.rows}, 1fr)`,
      // transform: "translate(14%, 0)",

      "& .participant_info": {
        display: "flex",
        flexWrap: "wrap",
        height: "80px",
        gap: "5px",
        alignItems: "center",
        color: "#FFD438",
        fontWeight: "bold",
        fontSize: 20,

        "& .content": {
          display: "flex",
          alignItems: "center",
          gap: "25px",
        },

        "& .number": {
          marginRight: 15,
        },
      },
    },
  },
  // "@keyframes pulsate-fwd": {
  //   "0%": {
  //     "-webkit-transform": "scale(1)",
  //     transform: "scale(1)",
  //   },
  //   "50%": {
  //     "-webkit-transform": "scale(1.1)",
  //     transform: "scale(1.1)",
  //   },
  //   "100%": {
  //     "-webkit-transform": "scale(1)",
  //     transform: "scale(1)",
  //   },
  // },
});

export default function PVPParticipatingTeams() {
  const { tournament, drawshow } = useSelector(
    (state: ReduxState) => state.live
  );

  const materialStyles = Styles({
    rows: drawshow?.size ?? 8,
  });

  if (!tournament) {
    return null;
  }

  // const GRID_LIMIt = 32;
  // const limit = tournament.participants.slice(0, GRID_LIMIt);

  return (
    <div className={materialStyles.screen}>
      <div className="wrapper">
        <Transition
          items={(drawshow?.pool ?? []).filter((_, i) => i < 32)}
          keys={(item) => item.name}
          from={{
            opacity: 0,
            transform: "translateX(-20px)",
          }}
          enter={{
            opacity: 1,
            transform: "translateX(0px)",
          }}
          leave={{
            opacity: 0,
            transform: "translateX(-20px)",
          }}
          trail={50}
        >
          {(team, state, i) => (props) =>
            (
              <div className="participant_info" style={props} key={i}>
                <div className="content">
                  <div
                    style={{
                      color: "#FFD438",
                    }}
                  >
                    {i + 1}
                  </div>
                  <span
                    style={{
                      color: "#ffffff",
                      fontWeight: "normal",
                      maxWidth: "180px",
                    }}
                  >
                    {team?.name}
                  </span>
                </div>
              </div>
            )}
        </Transition>
      </div>
    </div>
  );
}
