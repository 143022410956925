import { FC, HTMLProps } from "react";
import image from "@img/super_container.png";

const SuperContainer: FC<HTMLProps<HTMLDivElement>> = ({
  style,
  className,
  children,
}) => {
  return (
    <div
      style={{
        width: 1522,
        height: 199,
        backgroundSize: "100% 100%",
        backgroundImage: `url("${image}")`,
        ...style,
      }}
      className={className}
    >
      {children}
    </div>
  );
};

export default SuperContainer;
