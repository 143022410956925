import { tourna } from "../mock";
import QuestionImage from "../assets/imgs/questionMark.png";
import Box from "../assets/imgs/Box.png";
import { makeStyles } from "@material-ui/core";
import Groups from "../assets/imgs/groups.png";
import container from "../assets/imgs/groups_container.png";
import { ReduxState } from "../config/types/types";
import { useSelector } from "react-redux";
import PVPBox from "../comps/containers/PVPBox";
import { Transition } from "react-spring/renderprops-universal";
import { DrawTeam } from "../config/types/drawshow.type";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import NameTag from "@comps/containers/NameTag";

const Styles = makeStyles({
  screen: {
    position: "relative",
    backgroundSize: "100% 100%",
    // backgroundImage: `url("${Groups}")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 1080,
    width: 1920,

    "& .boxWrapper": {
      // position: "relative",
      // gap: "15px",
      // top: "10%",
      // left: "28%",
      // display: "flex",
      // flexWrap: "wrap",

      "& .box": {
        // backgroundImage: `url(${Box})`,
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url("${container}")`,
        width: 643,
        height: 799,
        position: "absolute",
        top: 44,
        paddingTop: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .nametag": {
          transform: "scale(0.7)",
        },
        "& .slideup-enter": {
          opacity: 0,
          transform: "translateY(-20px) scale(0.7)",
        },
        "& .slideup-exit": {
          opacity: 1,
          transform: "translateY(0px) scale(0.7)",
        },
        "& .slideup-enter-active": {
          opacity: 1,
          transform: "translateY(0px) scale(0.7)",
        },
        "& .slideup-exit-active": {
          opacity: 0,
          transform: "translateY(-20px) scale(0.7)",
        },
        "& .slideup-enter-active, .slideup-exit-active": {
          transition: "all 500ms cubic-bezier(0.25, 1, 0.5, 1)",
        },

        "& .transitions-enter": {
          opacity: 0,
          transform: "translateX(-20px)",
        },
        "& .transitions-exit": {
          opacity: 1,
          transform: "translateX(0px)",
        },
        "& .transitions-enter-active": {
          opacity: 1,
          transform: "translateX(0px)",
        },
        "& .transitions-exit-active": {
          opacity: 0,
          transform: "translateX(-20px)",
        },
        "& .transitions-enter-active, .transitions-exit-active": {
          transition: "all 300ms cubic-bezier(0.25, 1, 0.5, 1)",
        },

        "& .group_name": {
          transform: "translateY(24px)",
          textAlign: "center",
          maxWidth: "300px",
          margin: "0 auto",
          top: 0,
          textTransform: "uppercase",
          fontWeight: "bold",
          color: "#FFD438",
          fontSize: 30,
        },

        "& .wrapper": {
          flex: 1,
          display: "grid",
          gridAutoFlow: "column",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          columnGap: 20,
          padding: "0px 40px 40px 40px",

          "& .participant_info": {
            width: 300,
            display: "flex",
            // height: "80px",
            alignItems: "center",
            color: "#FFD438",
            fontWeight: "bold",
            fontSize: 15,

            "& .content": {
              textAlign: "left",
              display: "flex",
              alignItems: "center",
            },

            "& .number": {
              marginRight: 10,
              fontSize: 20,
            },
          },
        },
      },
    },

    "& .shuffle": {
      background: "transparent",
      border: "none",
      textTransform: "uppercase",
      color: "#FFD438",
      fontSize: 25,
      fontWeight: "bold",
      position: "absolute",
      bottom: "42.5px",
      left: "9.8%",
      cursor: "pointer",
    },

    "& .Ticker": {
      color: "#fff",
      textTransform: "uppercase",
      fontSize: 35,
      fontWeight: "bold",
      position: "absolute",
      maxWidth: "300px",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      bottom: "12%",
      left: "48%",
      right: "25%",
    },

    "& .lowerthird1": {
      color: "#FFD438",
      textTransform: "uppercase",
      fontSize: 22,
      fontWeight: "bold",
    },

    "& .castercam1": {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      maxWidth: "275px",
      position: "absolute",
      top: "41.6%",
      left: "6.3%",
      right: "25%",
    },

    "& .lowerthird2": {
      color: "#FFD438",
      textTransform: "uppercase",
      fontSize: 22,
      fontWeight: "bold",
    },

    "& .castercam2": {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      maxWidth: "275px",
      position: "absolute",
      top: "81.8%",
      left: "6.3%",
      right: "25%",
    },
  },
});

export default function PVPGroups() {
  const ms = Styles();
  const {
    casters,
    tournament,
    drawshow,
    drawshowLive = [],
    drawshowDuo,
  } = useSelector((state: ReduxState) => state.live);

  function shuffleCards(array: any) {
    var i = 0,
      j = 0,
      temp = null;
    for (i = array.length - 1; i > 0; i -= 1) {
      j = Math.floor(Math.random() * (i + 1));
      temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    console.log(array);
    return array;
  }

  const buttonClicked = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    shuffleCards(tournament?.participants);
  };

  console.log(drawshow?.size);

  const GRID_LIMIT = 32;
  const limit = tourna.slice(0, 8);

  const a = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  return (
    <div className={ms.screen}>
      {/* <PVPBox data={[...limit]} groupName={"Group B"} /> */}
      <div className="boxWrapper">
        <div
          className="box"
          style={{
            left: 527,
            transition: "all 600ms cubic-bezier(0.25, 1, 0.5, 1)",
            transform: drawshowDuo?.b
              ? "translateX(0px)"
              : `translateX(${643 / 2 + 96 / 2}px)`,
            zIndex: 1000,
          }}
        >
          <SwitchTransition>
            <CSSTransition
              key={a[drawshowDuo?.a || 0]}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames="slideup"
              unmountOnExit
            >
              <div className="nametag">
                <NameTag
                  title={`GROUP ${a[drawshowDuo?.a || 0]}`}
                  fontSize={60}
                  color="yellow"
                />
              </div>
            </CSSTransition>
          </SwitchTransition>
          <SwitchTransition>
            <CSSTransition
              key={
                drawshowLive.includes(drawshowDuo?.a || 0)
                  ? a[drawshowDuo?.a || 0]
                  : "placeholder"
              }
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames="transitions"
              unmountOnExit
            >
              <div
                className="wrapper"
                style={{
                  gridTemplateRows: `repeat(${Math.ceil(
                    (drawshow?.size ?? 20) / 2
                  )}, 1fr)`,
                }}
              >
                {drawshowLive.includes(drawshowDuo?.a || 0) ? (
                  <Transition
                    items={(drawshow?.result[drawshowDuo?.a || 0] ?? []).filter(
                      (t, i) => i < (drawshow?.size || 20)
                    )}
                    keys={(item) => item.name}
                    from={{
                      opacity: 0,
                      transform: "translateX(-20px)",
                    }}
                    enter={{
                      opacity: 1,
                      transform: "translateX(0px)",
                    }}
                    trail={100}
                  >
                    {(team, state, i) => (props) =>
                      i < (drawshow?.size || 20) && (
                        <div className="participant_info" style={props} key={i}>
                          <div className="content">
                            <div
                              style={{
                                fontWeight: "bold",
                                color: "#FFD438",
                                width: 40,
                                fontSize: 20,
                              }}
                            >
                              {i + 1}
                            </div>

                            <span
                              style={{
                                color: "#ffffff",
                                fontWeight: "normal",
                                fontSize: 20,
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {team?.name}
                            </span>
                          </div>
                        </div>
                      )}
                  </Transition>
                ) : (
                  <Transition
                    items={(drawshow?.result[0] ?? []).filter(
                      (t, i) => i < (drawshow?.size || 20)
                    )}
                    keys={(item) => item.name}
                    from={{
                      opacity: 0,
                      transform: "translateX(-20px)",
                    }}
                    enter={{
                      opacity: 1,
                      transform: "translateX(0px)",
                    }}
                  >
                    {(team, state, i) => (props) =>
                      i < (drawshow?.size || 20) && (
                        <div className="participant_info" style={props} key={i}>
                          <div className="content">
                            <div
                              style={{
                                fontWeight: "bold",
                                color: "#FFD438",
                                width: 40,
                                fontSize: 20,
                              }}
                            >
                              {i + 1}
                            </div>
                            <div
                              style={{
                                backgroundImage: `url("${
                                  team?.logo || QuestionImage
                                }")`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                flexShrink: 0,
                                width: "50px",
                                height: "50px",
                                marginRight: 10,
                              }}
                            />
                            <span
                              style={{
                                color: "#ffffff",
                                fontWeight: "normal",
                                fontSize: 20,
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              TEAM #{i + 1}
                            </span>
                          </div>
                        </div>
                      )}
                  </Transition>
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>

        <div
          className="box"
          style={{
            left: 1231,
            transition: "all 600ms cubic-bezier(0.25, 1, 0.5, 1)",
            opacity: drawshowDuo?.b ? 1 : 0,
            transform: drawshowDuo?.b ? "translateX(0px)" : "translateX(-40px)",
          }}
        >
          <SwitchTransition>
            <CSSTransition
              key={a[drawshowDuo?.b || 0]}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames="slideup"
              unmountOnExit
            >
              <div className="nametag">
                <NameTag
                  title={`GROUP ${a[drawshowDuo?.b || 0]}`}
                  fontSize={60}
                  color="yellow"
                />
              </div>
            </CSSTransition>
          </SwitchTransition>
          <SwitchTransition>
            <CSSTransition
              key={
                drawshowLive.includes(drawshowDuo?.b || 0)
                  ? a[drawshowDuo?.b || 0]
                  : "placeholder"
              }
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames="transitions"
              unmountOnExit
            >
              <div
                className="wrapper"
                style={{
                  gridTemplateRows: `repeat(${Math.ceil(
                    (drawshow?.size ?? 20) / 2
                  )}, 1fr)`,
                }}
              >
                {drawshowLive.includes(drawshowDuo?.b || 0) ? (
                  <Transition
                    items={(drawshow?.result[drawshowDuo?.b || 0] ?? []).filter(
                      (t, i) => i < (drawshow?.size || 20)
                    )}
                    keys={(item) => item.name}
                    from={{
                      opacity: 0,
                      transform: "translateX(-20px)",
                    }}
                    enter={{
                      opacity: 1,
                      transform: "translateX(0px)",
                    }}
                    trail={100}
                  >
                    {(team, state, i) => (props) =>
                      i < (drawshow?.size || 20) && (
                        <div className="participant_info" style={props} key={i}>
                          <div className="content">
                            <div
                              style={{
                                fontWeight: "bold",
                                color: "#FFD438",
                                width: 40,
                                fontSize: 20,
                              }}
                            >
                              {i + 1}
                            </div>

                            <span
                              style={{
                                color: "#ffffff",
                                fontWeight: "normal",
                                fontSize: 20,
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {team?.name}
                            </span>
                          </div>
                        </div>
                      )}
                  </Transition>
                ) : (
                  <Transition
                    items={(drawshow?.result[0] ?? []).filter(
                      (t, i) => i < (drawshow?.size || 20)
                    )}
                    keys={(item) => item.name}
                    from={{
                      opacity: 0,
                      transform: "translateX(-20px)",
                    }}
                    enter={{
                      opacity: 1,
                      transform: "translateX(0px)",
                    }}
                  >
                    {(team, state, i) => (props) =>
                      i < (drawshow?.size || 20) && (
                        <div className="participant_info" style={props} key={i}>
                          <div className="content">
                            <div
                              style={{
                                fontWeight: "bold",
                                color: "#FFD438",
                                width: 40,
                                fontSize: 20,
                              }}
                            >
                              {i + 1}
                            </div>
                            <div
                              style={{
                                backgroundImage: `url("${
                                  team?.logo || QuestionImage
                                }")`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                flexShrink: 0,
                                width: "50px",
                                height: "50px",
                                marginRight: 10,
                              }}
                            />
                            <span
                              style={{
                                color: "#ffffff",
                                fontWeight: "normal",
                                fontSize: 20,
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              TEAM #{i + 1}
                            </span>
                          </div>
                        </div>
                      )}
                  </Transition>
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>

      {casters && casters.length === 0 ? null : (
        <>
          {/* <div className="lowerthird1">
            {}
            <div className="castercam1 in-left">
              {casters && casters[0]?.name} test
            </div>
          </div>
          <div className="lowerthird2">
            <div className="castercam2 in-left">
              {casters && casters[1]?.name} test
            </div>
          </div> */}
          {/* <button className="shuffle" onClick={(event) => buttonClicked(event)}>
            shuffle
          </button> */}
        </>
      )}
    </div>
  );
}
