import { makeStyles } from "@material-ui/core";
import bg from "@img/casters.png";
import mic from "@img/mic.png";

const pad = 70;

export default makeStyles({
  comp: {
    width: 571,
    height: 168,
    overflow: "visibible",
    position: "relative",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: `url("${bg}")`,
    paddingLeft: 70,
  },

  caster: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "red",
    overflow: "hidden",
    height: 112,
    width: 112,
    borderWidth: 3,
    borderRadius: 1000,
    background: `linear-gradient(to top, #ffd231, #ea3a5a)`,
  },
  casterInner: {
    height: 107,
    width: 107,
    borderRadius: 1000,
    backgroundColor: "#001339",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  caster1: {
    position: "absolute",
    left: pad + 6,
    top: 9,
  },
  caster2: {
    position: "absolute",
    left: pad + 94,
    top: 51,
  },

  details: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100% - 52px)",
    position: "absolute",
    marginTop: 50,
    left: 290,
    width: 224,
    "& .mic": {
      height: 68,
      width: 43,
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url("${mic}")`,
      flexShrink: 0,
    },
    "& .casters": {
      display: "flex",
      flexDirection: "column",
      paddingLeft: 10,

      "& .caster": {
        color: "#fff",
        fontFamily: "Roboto",
        fontWeight: 900,
        textTransform: "capitalize",
        fontSize: 24,
        lineHeight: 1.1,
      },
    },
  },
});
