import { makeStyles } from "@material-ui/core";
import React from "react";

import bg from "@img/ingame.png";
import { useSelector } from "react-redux";
import { Live, ReduxState } from "@defs/types";
import useTournament from "@comps/hooks/useTournament";

const mcs = makeStyles({
  body: {
    width: 1920,
    height: 1080,
    position: "relative",
  },
  top: {
    width: "100%",
    height: 342,
    backgroundSize: "100% 100%",
    backgroundImage: `url("${bg}")`,
    position: "absolute",
    top: 0,
    left: 0,
  },
  teams: {
    display: "flex",
    position: "absolute",
    alignItems: "center",
    top: 63,
    left: 486,
    width: 983,
    height: 58,
    backgroundColor: "rgba(0,0,0,.5)",
    "& .name": {
      fontSize: 32,
      fontWeight: 900,
      color: "#fff",
      textAlign: "center",
      lineHeight: 1,
      flex: 1,
      whiteSpace: "nowrap",
    },
    "& .team": {
      display: "flex",
      height: "100%",
      alignItems: "center",
      "& .logo": {
        height: "75%",
        width: 73,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    },
    "& .team1": {
      width: 477,
      flexShrink: 0,
      height: "100%",
      "& .name": {
        paddingLeft: 20,
      },
    },
    "& .team2": {
      flexGrow: 1,
      height: "100%",
      flexShrink: 0,
      flexDirection: "row-reverse",
    },
  },
  bars: {
    display: "flex",
    height: "60%",
  },
  bar: {
    width: 8,
    display: "flex",
    backgroundColor: "#fff",
    margin: "0px 4px",
  },
});

const InGameMLBB = () => {
  const c = mcs();
  const { swap_team_positions: swap } = useSelector<ReduxState, Live>(
    (s) => s.live
  );
  const { team, match, score } = useTournament();
  const team1 = swap ? team(match?.player2_id) : team(match?.player1_id);
  const team2 = swap ? team(match?.player1_id) : team(match?.player2_id);
  const team1_score = score(match, team1?.id).wins;
  const team2_score = score(match, team2?.id).wins;
  console.log("bestof", match?.bestOf);
  console.log("max wins", Math.ceil((match?.bestOf ?? 1) / 2));
  return (
    <div className={c.body}>
      <div className={c.top}>
        <div className={c.teams}>
          <div className="team1 team">
            <div
              className="logo"
              style={{
                backgroundImage: `url("${team1?.logo_base64 || team1?.logo}")`,
              }}
            ></div>
            <div className="name">{team1?.org_name}</div>
            <div
              className={c.bars}
              style={{ transform: "skew(15deg)", marginRight: 30 }}
            >
              {[...new Array(Math.ceil((match?.bestOf ?? 1) / 2))].map(
                (bar, i) => (
                  <div
                    key={i}
                    className={c.bar}
                    style={{
                      backgroundColor: team1_score > i ? "#ffd231" : "#fff",
                    }}
                  ></div>
                )
              )}
            </div>
          </div>
          <div className="team2 team">
            <div
              className="logo"
              style={{
                backgroundImage: `url("${team2?.logo_base64 || team2?.logo}")`,
              }}
            ></div>
            <div className="name">{team2?.org_name}</div>
            <div
              className={c.bars}
              style={{
                transform: "skew(-15deg)",
                marginLeft: 30,
                flexDirection: "row-reverse",
              }}
            >
              {[...new Array(Math.ceil((match?.bestOf ?? 1) / 2))].map(
                (bar, i) => (
                  <div
                    key={i}
                    className={c.bar}
                    style={{
                      backgroundColor: team2_score > i ? "#ffd231" : "#fff",
                    }}
                  ></div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InGameMLBB;
