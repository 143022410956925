import { makeStyles } from "@material-ui/core";
import React from "react";
import bg from "@img/castercam_frame.png";
import { useSelector } from "react-redux";
import { Live, ReduxState } from "@defs/types";

const mcs = makeStyles<any, { flipped: boolean }>({
  body: {
    height: 513,
    width: 879,
    position: "relative",
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center",

    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      backgroundSize: "100% 100%",
      backgroundImage: `url("${bg}")`,
      transform: (props) => `scaleX(${props.flipped ? -1 : 1})`,
      zIndex: -1,
    },
  },
  name: {
    fontWeight: 900,
    width: 305,
    marginBottom: 39,
    fontSize: 32,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFD438",
    textAlign: "center",
    textTransform: "uppercase",
  },
});
const CasterFrame = ({
  flipped = false,
  casterIndex,
}: {
  flipped?: boolean;
  casterIndex: number;
}) => {
  const c = mcs({ flipped });
  const { casters = [] } = useSelector<ReduxState, Live>((s) => s.live);
  return (
    <div className={c.body}>
      <div className={c.name}>{casters[casterIndex]?.ign}</div>
    </div>
  );
};

export default CasterFrame;
