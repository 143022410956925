import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Live, ReduxState } from "@defs/types";
import SuperTimeline from "./SuperTimeline";
import SuperAchieved from "./SuperAchieved";

const mcs = makeStyles({
  body: {
    height: 1080,
    width: 1920,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "relative",
  },
  container: {
    position: "absolute",
    bottom: 32,
  },
});

const styles = (live: boolean = false): React.CSSProperties => ({
  transitionProperty: "all",
  willChange: "opacity, transform",
  transitionDelay: live ? "600ms" : "0ms",
  transitionDuration: live ? "300ms" : "600ms",
  transitionTimingFunction: "cubic-bezier(0.33, 1, 0.68, 1)",
  opacity: live ? 1 : 0,
  transform: `translateY(${live ? "0px" : "40px"})`,
});

const Super = () => {
  const c = mcs();
  const { timeline } = useSelector<ReduxState, Live>((s) => s.live);
  return (
    <div className={c.body}>
      <SuperTimeline
        props={{ className: c.container, style: styles(timeline?.isLive) }}
        timeline={timeline}
      />
      {timeline?.items.map((item, i) => (
        <SuperAchieved
          props={{ className: c.container, style: styles(item.show) }}
          item={item}
        ></SuperAchieved>
      ))}
    </div>
  );
};
export default Super;
