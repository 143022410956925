import { TimelineItem } from "@defs/timeline.interface";
import { makeStyles } from "@material-ui/core";
import React, { FC, HTMLProps, useEffect } from "react";
import { useRef } from "react";
import Reward, { RewardElement } from "react-rewards";
import SuperContainer from "./SuperContainer";

const mcs = makeStyles({
  wrap: {
    height: "100%",
    width: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  viewers: {
    width: 549,
    display: "flex",
    flexDirection: "column",
    fontWeight: 900,
    alignItems: "center",
    justifyContent: "center",
    lineHeight: 1,
    borderRight: "3px solid #f2be09",
    "& .main": {
      color: "#f2be09",
      fontSize: 70,
    },
    "& .sub": {
      color: "#fff",
      fontSize: 50,
    },
  },
  item: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    height: "100%",
    padding: "0px 50px",
    "& .image": {
      width: 200,
      height: "150%",
      backgroundPosition: "center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      flexShrink: 0,
      //   filter: "drop-shadow(0px 8px 8px rgba(0,0,0,.5))",
    },

    "& .text": {
      padding: "0px 25px",
      fontWeight: 700,
      lineHeight: 1,
      color: "#f2be09",
      fontSize: "2rem",
      display: "flex",
      height: "100%",
      flex: 1,
      alignItems: "center",
    },
  },
});

interface SuperAchieveProps {
  props: HTMLProps<HTMLDivElement>;
  item: TimelineItem;
}

const SuperAchieved: FC<SuperAchieveProps> = ({ props, item }) => {
  const c = mcs();
  const ref = useRef<RewardElement>(null);

  useEffect(() => {
    if (!item.show) return;

    setTimeout(() => {
      ref.current?.rewardMe();
    }, 600);
  }, [item.show]);
  return (
    <div {...props}>
      <SuperContainer>
        <div className={c.wrap}>
          <div className={c.viewers}>
            <div className="main">{item.value} VIEWERS</div>
            <div className="sub">GIVEAWAY</div>
          </div>
          <div className={c.item}>
            <div
              className="image"
              style={{ backgroundImage: `url("${item.opposite?.image}")` }}
            ></div>
            <div className="text">{item.opposite?.text}</div>
          </div>
        </div>
      </SuperContainer>
      <div style={{ position: "absolute", left: "50%", top: "50%" }}>
        <Reward
          ref={ref}
          type="confetti"
          config={{
            spread: 180,
            springAnimation: false,
            elementCount: 500,
            lifetime: 300,
            startVelocity: 100,
          }}
        >
          <div className=""></div>
        </Reward>
      </div>
    </div>
  );
};

export default SuperAchieved;
