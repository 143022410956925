import { makeStyles } from "@material-ui/core";
import React from "react";

// @ts-ignore
import Timer from "../comps/timer/Timer";
import { useSelector } from "react-redux";
import { ReduxState } from "../config/types/types";
import timerFrame from "@img/timer2.png";

const ms = makeStyles((theme) => ({
  timer: {
    backgroundSize: "100% 100%",
    backgroundImage: `url(${timerFrame})`,
    height: 147,
    width: 469,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    "& .time": {
      color: "#fff",
      fontSize: 90,
      fontWeight: 900,
      textAlign: "center",
      lineHeight: 1,
      padding: "2px 20px",
    },
  },
}));

const TimerPVP: React.FC<{ className?: string }> = ({
  className,
  ...props
}) => {
  const c = ms();
  const { countdown_minutes = 0 } = useSelector(
    (state: ReduxState) => state.live
  );

  return (
    <div className={c.timer + " " + className} {...props}>
      <div className="time">
        <Timer expiryTimestamp={countdown_minutes} />
      </div>
    </div>
  );
};

export default TimerPVP;
