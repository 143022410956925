import useAdjustments from "@comps/hooks/useAdjustments";
import { Live, ReduxState } from "@config/types/types";
import { adjustmentDefaultValues } from "@config/types/adjustments.interface";
import React from "react";
import { VFC } from "react";
import { useSelector } from "react-redux";
import AdStyles from "./Ad.styles";

const Ad: VFC = () => {
  const c = AdStyles();
  const { lowerThirdsIngame } = useSelector<ReduxState, Live>(
    (state) => state.live
  );

  const ad = lowerThirdsIngame?.ad
  const adjust = useAdjustments();
  return (
    <div className={c.body}>
      <div className="head" style={adjust({font_size: ad?.head?.adj?.font_size})}>{ad?.head?.text}</div>
      <div className="body">{ad?.content.text}</div>
      <div className="s-img-wrap">
        <div
          className="s-img"
          style={{
            backgroundImage: `url("${ad?.img?.url}")`,
            ...adjust(
              ad?.img?.adj ?? adjustmentDefaultValues
            ),
            height: ad?.img?.adj?.h || "100%",
            width: ad?.img?.adj?.w || "100%",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Ad;
