export interface AdjustmentsProps {
  y: number;
  x: number;
  scale: number;
  flip_x?: boolean;
  flip_y?: boolean;
  font_size?: number;
  h?: number;
  w?: number;
}

export const adjustmentDefaultValues: AdjustmentsProps = {
  x: 0,
  y: 0,
  scale: 0,
  flip_x: false,
  flip_y: false,
  font_size: 0,
  h: 0,
  w: 0,
};
