import { makeStyles } from "@material-ui/core";
import React from "react";
import frame from "@img/BoxClean.png";
import NameTag from "@comps/containers/NameTag";
import { useSelector } from "react-redux";
import { Live, ReduxState } from "../config/types/types";
import defaultLogo from "@img/questionMark.png";
import { Transition as SpringTransition } from "react-spring/renderprops";
import container from "../assets/imgs/groups_container.png";
import {
  CSSTransition,
  SwitchTransition,
  Transition,
} from "react-transition-group";

const w = 643;
const h = 799;

const styles = makeStyles({
  body: {
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url("${container}")`,
    width: 643,
    height: 799,
    position: "absolute",
    paddingTop: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    height: "100%",
    width: "100%",
    //   clipPath: `polygon(
    // 	${(13 / w) * 100}%  ${(20 / h) * 100}%,
    // 	${100 - (32 / w) * 100}%  ${(2 / h) * 100}%,
    // 	${100 - (32 / w) * 100}%  ${100 - (33 / h) * 100}%,
    // 	${(13 / w) * 100}%  ${100 - (10 / h) * 100}%
    // )`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,

    "& .csstransitions-enter": {
      opacity: 0,
      transform: "translateX(-20px)",
    },
    "& .csstransitions-exit": {
      opacity: 1,
      transform: "translateX(0px)",
    },
    "& .csstransitions-enter-active": {
      opacity: 1,
      transform: "translateX(0px)",
    },
    "& .csstransitions-exit-active": {
      opacity: 0,
      transform: "translateX(-20px)",
    },
    "& .csstransitions-enter-active, .csstransitions-exit-active": {
      transition: "all 300ms",
    },

    "& .div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
  },
  group: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    gridTemplateRows: (props: { rows: number }) => `repeat(${props.rows}, 1fr)`,
    height: 620,

    marginTop: 10,
    width: "100%",
    padding: "0px 30px",
  },
  team: {
    display: "flex",
    alignItems: "center",
    "& .logo": {
      height: 50,
      width: 50,
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      margin: "0px 20px",
    },

    "& .name": {
      // fontWeight: "bold",
      lineHeight: 1,
      color: "#fff",
      fontSize: 26,
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
    },
  },
});

const Group = () => {
  const { drawshow, drawshowSingle = null } = useSelector<ReduxState, Live>(
    (state) => state.live
  );
  console.log(Math.ceil((drawshow?.size ?? 8) / 2));
  const c = styles({ rows: Math.ceil((drawshow?.size ?? 8) / 2) });
  const a = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  return (
    <div className={c.body}>
      <div className={c.content}>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={`GROUP ${a[drawshowSingle ?? 0]}`}
            addEndListener={(node, done) =>
              node.addEventListener("transitionend", done, false)
            }
            timeout={300}
            classNames="csstransitions"
          >
            <div className="div">
              <div
                className=""
                style={{
                  transform: "scale(0.7)",
                }}
              >
                <NameTag
                  title={`GROUP ${a[drawshowSingle ?? 0]}`}
                  style={{
                    marginTop: 40,
                  }}
                  color="yellow"
                  fontSize={65}
                />
              </div>
              <div className={c.group}>
                {drawshowSingle !== null &&
                  drawshow?.result &&
                  (drawshow?.result[drawshowSingle] ?? []).map((team, i) => (
                    <div className={c.team} key={i}>
                      <div
                        style={{
                          color: "#FFD438",
                          marginRight: 20,
                          marginLeft: 20,
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {i + 1}.
                      </div>
                      {/* <div
                        className="logo"
                        style={{
                          backgroundImage: `url("${
                            team?.logo || defaultLogo
                          }")`,
                        }}
                      ></div> */}

                      <div className="name">{team.name}</div>
                    </div>
                  ))}
              </div>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
};

export default Group;
