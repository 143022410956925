import React from "react";
import "./App.css";
import { makeStyles } from "@material-ui/core";
import { Route, RouteProps, Switch } from "react-router-dom";
import ModuleContainer from "@view/ModuleContainer";
import Bracket from "@view/Bracket";
import Slider from "@view/pops/Slider.page";
import BracketContainer from "@view/BracketContainer";
import PVPTimer from "@view/PVPTimer";
import Groups from "./view/PVPGroups";
import PVPParticipatingTeams from "@view/PVPParticipatingTeams";
import Group from "@view/Group";
import CasterCam from "@view/CasterCam";
import Drafting from "@view/Drafting";
import InGameMLBB from "@view/InGameMLBB";
import PostGameMLBB from "@view/PostGameMLBB";
import TimerPVP from "@view/TimerNew";
import Super from "@view/Super/Super";

const ms = makeStyles({
  app: {
    overflow: "hidden",
    width: 1920,
    height: 1080,
    backgroundColor: "transparent",
  },
});

const routes = [
  { path: "/ingame/sliders", component: <Slider />, exact: true },
  { path: "/ingame/mlbb", component: <InGameMLBB />, exact: true },
  { path: "/postgame/mlbb", component: <PostGameMLBB />, exact: true },
  { path: "/allbracket", component: <BracketContainer />, exact: true },
  { path: "/bracket", component: <Bracket />, exact: true },
  { path: "/lbracket", component: <Bracket isLowerBracket />, exact: true },
  { path: "/content", component: <ModuleContainer />, exact: true },
  { path: "/PVPTimer", component: <PVPTimer />, exact: true },
  { path: "/castercam", component: <CasterCam />, exact: true },
  { path: "/draft", component: <Drafting />, exact: true },
  { path: "/group", component: <Group />, exact: true },
  { path: "/groups", component: <Groups />, exact: true },
  { path: "/participants", component: <PVPParticipatingTeams />, exact: true },
  { path: "/timer", component: <TimerPVP />, exact: true },
  { path: "/super", component: <Super />, exact: true },
];

function App() {
  const c = ms();
  return (
    <div className={c.app}>
      <Switch>
        {routes.map(({ component, ...route }) => (
          <Route key={route.path} {...route}>
            {component}
          </Route>
        ))}
      </Switch>
    </div>
  );
}

export default App;
