import { makeStyles } from "@material-ui/core";
import React from "react";
import bg from "@img/TeamNameTag.png";
import { Match, Participant } from "@defs/types";
import useTournament from "@comps/hooks/useTournament";
import { useLocation, useParams } from "react-router-dom";
import qs from "qs";

const mcs = makeStyles<any, { flipped: boolean }>({
  body: {
    height: 107,
    width: 652,
    position: "relative",
    display: "flex",
    flexDirection: (props) => (props.flipped ? "row-reverse" : "row"),
    zIndex: 10,
    alignItems: "center",
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      backgroundSize: "100% 100%",
      backgroundImage: `url("${bg}")`,
      transform: (props) => `scaleX(${props.flipped ? -1 : 1})`,
      zIndex: -1,
    },
  },
  logo: {
    width: 86,
    height: 80,
    display: "flex",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    marginLeft: (props) => (props.flipped ? 20 : 28),
    marginRight: (props) => (props.flipped ? 28 : 20),
    flexShrink: 0,
  },
  text: {
    display: "flex",
    flexDirection: "column",
    textAlign: (props) => (props.flipped ? "right" : "left"),
    flex: 1,

    "& .org": {
      fontWeight: 900,
      fontSize: 34,
      textTransform: "uppercase",
      lineHeight: 1,
      color: "#fbd239",
    },
    "& .school": {
      fontSize: 20,
      textTransform: "uppercase",
      lineHeight: 1,
      color: "#fff",
    },
  },
  score: {
    fontWeight: 900,
    fontSize: 60,
    textTransform: "uppercase",
    lineHeight: 1,
    color: "#fff",
    width: 92,
    textAlign: "center",
    borderLeft: (props) =>
      props.flipped ? "none" : "1px solid rgba(255,255,255,.5)",
    borderRight: (props) =>
      props.flipped ? "1px solid rgba(255,255,255,.5)" : "none",
    transform: "translateY(-6px)",
    marginRight: (props) => (props.flipped ? 25 : 40),
    marginLeft: (props) => (props.flipped ? 40 : 25),
  },
});

const TeamNameTag = ({
  team,
  match,
  flipped = false,
}: {
  team?: Participant;
  match?: Match;
  disableSchool?: boolean;
  flipped?: boolean;
}) => {
  const c = mcs({ flipped });
  const params = useLocation().search;
  const { hideSchool } = qs.parse(params, { ignoreQueryPrefix: true });
  const { score } = useTournament();
  return (
    <div className={c.body}>
      <div
        className={c.logo}
        style={{ backgroundImage: `url("${team?.logo_base64 || team?.logo}")` }}
      ></div>
      <div className={c.text}>
        <div className="org">{team?.org_name}</div>
        {!hideSchool && (
          <div className="school">{team?.university_acronym}</div>
        )}
      </div>
      <div className={c.score}>{score(match, team?.id).wins}</div>
    </div>
  );
};

export default TeamNameTag;
