import React from "react";
import Bracket from "./Bracket";

const BracketContainer = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Bracket />
      <Bracket isLowerBracket />
    </div>
  );
};

export default BracketContainer;
