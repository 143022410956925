import { AdjustmentsProps } from "@config/types/adjustments.interface";
import { CSSProperties } from "react";

function useAdjustments() {
  const adjust = (props: Partial<AdjustmentsProps>): CSSProperties => {
    return {
      transform: `
        translateX(${props.x ?? 0}px)
        translateY(${props.y ?? 0}px)
        scale(${1 + (props.scale ?? 0) * 0.01})
        scaleX(${1 * (props.flip_x ? -1 : 1)})
        scaleY(${1 * (props.flip_y ? -1 : 1)})
        `,
      fontSize: props.font_size || '',
      transition: "all 0.6s cubic-bezier(0.65, 0, 0.35, 1)"
    };
  };

  return adjust;
}

export default useAdjustments;
