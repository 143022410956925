import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Match, ReduxState } from "../config/types/types";
// import bracketPng from "../assets/imgs/bracket.png";
import useTournament from "@comps/hooks/useTournament";
import { FC } from "react";
import { match } from "react-router-dom";
import teamBG from "../assets/imgs/bracket-team.png";
import UPlines from "../assets/imgs/upper-bracket-line-new-new.png";
import LBlines from "../assets/imgs/lb-lines-new-new.png";
import { config, Transition } from "react-spring/renderprops";

const mcs = makeStyles((theme) => ({
  container: {
    width: 1220,
    height: 605,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  rounds: {
    display: "flex",
  },

  matches: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    height: 467,
  },

  match: {
    display: "flex",
    flexDirection: "column",
    margin: 10,
    border: "1px solid #000",
    width: 200,
    minHeight: 50,
  },

  bracketLines: {
    position: "absolute",
    // top: 43,
    // left: 232,
    // height: 106,
    // width: 485,
    height: 106,
    width: 256,
    // display: "flex",
    backgroundSize: "100% 100%",
    backgroundImage: `url("${UPlines}")`,
    transform: "scale(1.34)",
    zIndex: 99,
    // 180, 338
  },

  lbLines: {
    position: "absolute",
    height: 3,
    width: 235,
    transform: " scale(1.2)",
    backgroundSize: "100% 100%",
    backgroundImage: `url("${LBlines}")`,
    zIndex: 99,
  },

  lbMatches: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    height: 184,
  },

  lbteam: {
    display: "flex",
    width: 196,
    height: 33,
    backgroundSize: "100% 100%",
    backgroundImage: `url("${teamBG}")`,
    color: "#fbd239",
    textTransform: "uppercase",
    padding: 2,

    "& .score": {
      marginRight: 2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: 36,
      lineHeight: 1,
      flexShrink: 0,
      paddingTop: 2,
      fontWeight: "bold",
      fontSize: 16,
    },
    "& .teamname": {
      display: "flex",
      alignItems: "center",
      height: "100%",
      flex: 1,
      lineHeight: 1,
      padding: "2px 10px 0px 10px",
      fontWeight: "bold",
      fontSize: 16,
    },
  },

  lbfinalwrapper: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    position: "relative",

    "& .matchname": {
      position: "absolute",
      bottom: "calc(100% - 35px)",
      color: "#fff",
      lineHeight: 1,
      fontSize: 13,
      fontWeight: 100,
      textTransform: "uppercase",
      transform: "translateY(36px)",
    },
  },
}));

const Bracket: FC<{ isLowerBracket?: boolean }> = ({ isLowerBracket }) => {
  const c = mcs();
  // const { tournament } = useSelector((state: ReduxState) => state.live);

  const { matches, score, getMatch, team } = useTournament();

  // upper bracket matches unordered
  const UBMU = matches.filter((m) => m.round > 0).filter((m) => !m.optional);
  const LBMU = matches.filter((m) => m.round < 0);
  const [ThirdPlacerMatch] = matches.filter(
    (m) =>
      m.prerequisite_match_ids_csv.split(",").filter(Boolean).length === 1 &&
      m.round > 0
  );

  const UBMO = organizeMatches(UBMU);
  const LBMO = organizeMatches(LBMU);

  const grandFinals = UBMO[UBMO.length - 1]?.find(
    (m) => m.prerequisite_match_ids_csv.split(",").length == 2
  );
  function organizeMatches(MU: Match[]) {
    const rounds = Array.from(new Set(MU.map((m) => m.round)));
    return rounds.map((r) => MU.filter((m) => m.round === r));
  }

  return !isLowerBracket ? (
    <div className={c.container}>
      <Transition
        items={true}
        from={{
          opacity: 0,
          clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
        }}
        enter={{
          opacity: 1,
          clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        }}
        leave={{
          opacity: 0,
          clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
        }}
        config={config.molasses}
        delay={600}
      >
        {(item) => (props) =>
          <div className={c.bracketLines} style={props}></div>}
      </Transition>

      {/*  */}
      <div className={c.rounds}>
        {UBMO.map((r, ri) => (
          <div
            className={c.matches}
            key={ri}
            style={{ transform: "translateX(0)" }}
          >
            {r.map((m, mi) => (
              <BracketMatch
                match={m}
                key={mi}
                roundIndex={ri}
                matchIndex={mi}
                matches={UBMO}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className={c.container}>
      <div className={c.lbLines}></div>
      <div className={c.rounds}>
        {LBMO.map((r, ri) => (
          <div
            className={c.lbMatches}
            key={ri}
            style={{
              transform: `translateY(${
                ri === 0 ? 41 : ri === 1 ? 41 : ri === 2 ? 0 : -40
              }px) translateX(3px)`,
            }}
          >
            {r.map((m, mi) => (
              <LowerBracketMatch
                match={m}
                key={mi}
                roundIndex={ri}
                matchIndex={mi}
                matches={LBMO}
              />
            ))}
          </div>
        ))}
        <div className={c.lbfinalwrapper}>
          <div className="matchname">Championship Final</div>
          <div className={c.lbteam} style={{ transform: "translateY(-2px)" }}>
            <div className="score">
              {Boolean(grandFinals?.player2_id)
                ? score(getMatch(grandFinals?.id ?? 0), grandFinals?.player2_id)
                    .wins
                : "-"}
            </div>
            <div className="teamname">
              {team(grandFinals?.player2_id)?.org_name ||
                team(grandFinals?.player2_id)?.display_name ||
                "TBD"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const matchClasses = makeStyles({
  wrapper: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },

  container: {
    display: "flex",
    flexDirection: "column",
    height: 90,
    justifyContent: "space-between",
    // margin: "26.5px 128px 26.5px 0px",
    position: "relative",
    overflow: "visible",

    "& .matchname": {
      position: "absolute",
      bottom: "calc(100% + 4px)",
      color: "#fff",
      lineHeight: 1,
      fontSize: 16,
      fontWeight: 100,
      textTransform: "uppercase",
    },
  },

  team: {
    display: "flex",
    width: 229,
    height: 39,
    backgroundSize: "100% 100%",
    backgroundImage: `url("${teamBG}")`,
    color: "#fbd239",
    textTransform: "uppercase",
    padding: 2,

    "& .score": {
      marginRight: 2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: 42,
      lineHeight: 1,
      flexShrink: 0,
      paddingTop: 0,
      fontWeight: "bold",
      fontSize: 22,
    },
    "& .teamname": {
      display: "flex",
      alignItems: "center",
      height: "100%",
      flex: 1,
      lineHeight: 1,
      padding: "0px 10px",
      fontSize: 18,
      fontWeight: "bold",
    },
  },
});

const BracketMatch: React.FC<{
  matches: Match[][];
  match: Match;
  roundIndex: number;
  matchIndex: number;
}> = ({ match, roundIndex, matchIndex, matches }) => {
  const c = matchClasses();
  const { team, score, getMatch } = useTournament();

  const matchNamer = () => {
    if (roundIndex === 0) return `Round 1`;
    if (roundIndex === 1 && matchIndex === 0) return `Winners Finals`;
    if (roundIndex === 2) return `Grand Finals`;
    if (roundIndex === 3) return "FINALS";
  };
  return (
    <Transition
      items={true}
      from={{ opacity: 0, transform: "translateY(-5px)" }}
      enter={{ opacity: 1, transform: "translateY(0px)" }}
      leave={{ opacity: 0, transform: "translateY(-5px)" }}
      delay={roundIndex * 600 + matchIndex * 150}
    >
      {(item) => (props) =>
        (
          <div
            className={c.wrapper}
            style={{
              flex: roundIndex === 0 ? 0 : 1,
              marginRight:
                roundIndex === 0
                  ? 70
                  : roundIndex === 1
                  ? 70
                  : roundIndex === matches.length - 1
                  ? 0
                  : 65,
              ...props,
            }}
          >
            <div
              className={c.container}
              style={{
                transform: `translateY(${
                  roundIndex === 0 && matchIndex === 0
                    ? 119
                    : roundIndex === 0 && matchIndex === 1
                    ? -119
                    : roundIndex === 1 && matchIndex === 0
                    ? 0
                    : roundIndex === 1 && matchIndex === 1
                    ? -48
                    : "0"
                }px)`,
                justifyContent:
                  roundIndex === matches?.length - 1
                    ? "center"
                    : "space-between",
              }}
            >
              <div
                className="matchname"
                style={{
                  transform: `translateY(${
                    roundIndex === matches.length - 1 ? 26 : 0
                  }px)`,
                }}
              >
                {matchNamer()}
              </div>
              <div className={c.team}>
                <div className="score">
                  {Boolean(match.player1_id)
                    ? score(getMatch(match.id), match.player1_id).wins
                    : "-"}
                </div>
                <div className="teamname">
                  {team(match.player1_id)?.org_name ||
                    team(match.player1_id)?.display_name ||
                    "TBD"}
                </div>
              </div>

              {roundIndex !== matches?.length - 1 && (
                <div className={c.team}>
                  <div className="score">
                    {Boolean(match.player2_id)
                      ? score(getMatch(match.id), match.player2_id).wins
                      : "-"}
                  </div>
                  <div className="teamname">
                    {team(match.player2_id)?.org_name ||
                      team(match.player2_id)?.display_name ||
                      "TBD"}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
    </Transition>
  );
};

const lbcs = makeStyles({
  wrapper: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: 76,
    justifyContent: "space-between",
    // margin: "26.5px 128px 26.5px 0px",
    position: "relative",

    "& .matchname": {
      position: "absolute",
      bottom: "calc(100% + 4px)",
      color: "#fff",
      lineHeight: 1,
      fontSize: 13,
      fontWeight: 100,
      textTransform: "uppercase",
    },
  },

  team: {
    display: "flex",
    width: 196,
    height: 33,
    backgroundSize: "100% 100%",
    backgroundImage: `url("${teamBG}")`,
    color: "#fbd239",
    textTransform: "uppercase",
    padding: 2,

    "& .score": {
      marginRight: 2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: 36,
      lineHeight: 1,
      flexShrink: 0,
      paddingTop: 2,
      fontWeight: "bold",
      fontSize: 16,
    },
    "& .teamname": {
      display: "flex",
      alignItems: "center",
      height: "100%",
      flex: 1,
      lineHeight: 1,
      padding: "2px 10px 0px 10px",
      fontSize: 16,
      fontWeight: 500,
    },
  },
});

const LowerBracketMatch: React.FC<{
  matches: Match[][];
  match: Match;
  roundIndex: number;
  matchIndex: number;
}> = ({ match, matches, roundIndex, matchIndex }) => {
  const c = lbcs();
  const { team, score, getMatch } = useTournament();

  const matchNamer = () => {
    if (roundIndex === 0) return `Losers Round 1`;
    if (roundIndex === 1) return `Losers Final`;
    if (roundIndex === 2) return `Grand Finals`;
    if (roundIndex === 3) return "Finals";
  };
  return (
    <div
      className={c.wrapper}
      style={{
        transform: "translateY(10px)",
        flex: roundIndex === 0 ? 0 : roundIndex === 1 ? 0 : 1,
        marginRight:
          roundIndex === 0
            ? 60
            : roundIndex === 1
            ? 60
            : roundIndex === 2
            ? 40
            : 40,
      }}
    >
      <div className={c.container}>
        <div className="matchname">{matchNamer()}</div>
        <div className={c.team}>
          <div className="score">
            {Boolean(match.player1_id)
              ? score(getMatch(match.id), match.player1_id).wins
              : "-"}
          </div>
          <div className="teamname">
            {team(match.player1_id)?.org_name ||
              team(match.player1_id)?.display_name ||
              "TBD"}
          </div>
        </div>

        <div className={c.team}>
          <div className="score">
            {Boolean(match.player2_id)
              ? score(getMatch(match.id), match.player2_id).wins
              : "-"}
          </div>
          <div className="teamname">
            {team(match.player2_id)?.org_name ||
              team(match.player2_id)?.display_name ||
              "TBD"}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Bracket;
