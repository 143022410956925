import Timer from "../assets/imgs/timer.png";
import { useSelector } from "react-redux";
import { ReduxState } from "../config/types/types";
import TimerComponent from "../comps/timer/Timer";
import { makeStyles } from "@material-ui/core";

const Styles = makeStyles({
  screen: {
    position: "relative",
    backgroundImage: `url(${Timer})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 1080,
    width: 1920,

    "& .timer": {
      position: "absolute",
      bottom: 0,
      left: "50%",
      right: "25%",
      maxWidth: "300px",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      marginLeft: "-150px",
      marginBottom: "50px",

      "& .starting": {
        color: "#004fff",
        marginTop: 85,
        fontSize: 25,
        paddingBottom: 20,
        borderBottom: "3px solid #ffd200",
        fontFamily: "Druk Wide Bold",
        textTransform: "uppercase",
      },

      "& .time": {
        color: "#ffffff",
        fontSize: 50,
        fontFamily: "Druk Wide Bold",
        display: "flex",
        JustifyContent: "center",
      },

      "& .game": {
        marginTop: 45,
        marginLeft: 10,
        height: 105,
        width: 300,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    },
  },
});

export default function PVPTimer() {
  const ms = Styles();
  const { countdown_minutes = Date.now(), tournament } = useSelector(
    (state: ReduxState) => state.live
  );

  return (
    <div className={ms.screen}>
      <div className="timer">
        <div className="time">
          <TimerComponent expiryTimestamp={countdown_minutes} />
        </div>
      </div>
    </div>
  );
}
